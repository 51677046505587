.root {
    margin: 0 20px;
    /*width: 148px;*/
    font-size: 20px;
    text-align: left;
    position: absolute;
    font-weight: 500;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    height: calc(100% - 324px);
    z-index: 1001;
}

.mobileMenuButton {
    display: none;
    background: url('/img/svg/hamburger.svg');
    background-size: cover;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -140px;
}

.menu {
    list-style: none;
}

.menu>li {
    cursor: pointer;
    margin:  0 0 6px;
    padding: 10px 20px;
    /*border-radius: 40px;*/
    //width: fit-content;
    white-space: nowrap;
    border-radius: 12px;
    width: 100%;

    &.active {
        background: linear-gradient(#191970, #451c80);
    }

    &:hover {
        background: linear-gradient(#191970, #451c80);
    }
}

@media (max-width: 710px) {
    .mobileMenuButton {
        display: flex;
    }
    .menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: -80px;
        background: #13111a;
        border-radius: 12px;
        padding: 12px;
    }
    .mobileMenuActive {
        display: flex;
    }
}
