.root {
  p {
    margin: 20px 0;
  }

  ul {
    margin-left: 30px;

    >li {
      margin-bottom: 10px;
    }
  }
}
