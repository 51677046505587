.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addressContainer {
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 24px;

  a {
    cursor: pointer;
    color: #9f9fe9;
    text-decoration: none;

    &:link, &:visited {
      color: #9f9fe9;
      text-decoration: none;
    }
  }
}
