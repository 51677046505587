.root {
    padding-top: 40px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}

.logos {
    margin-top: 50px;
}

.icon {
    display: inline-block;
    margin: 10px;
}

.icon svg {
    fill: #9f9fe9;
    height: 200px;
    width: 200px;

    path {
        fill: #9f9fe9;
    }
}
