.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.photo {
  border-radius: 24px;
  height: 200px;
  width: 200px;
}

.name {
  margin-top: 12px;
  color: #dfdff8;
}

.position {
  margin-top: 2px;
  color: #9f9fe9;
}
