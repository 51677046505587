body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #fefeff;
  /*color: #000;*/
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: rgb(8,5,16);
  /*background: url("./img/bg.jpg");*/
  /*background-size: cover;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#canvas {
  position: absolute;
  /*background: url("./img/bg.jpg");*/
  z-index: -1;
  top: 0;
  left: 0;
  /*width: 100%;*/
  /*height: 100%;*/
  cursor: none;
}
#stats {
  position: absolute;
  z-index: 0;
  left: 10px;
  top: 10px;
}
.dg.ac {
  z-index: 1;
}

.white {
  color: #000;
}
