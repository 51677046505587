.root {
    padding-top: 10px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}

.services {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
}

.serviceBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.3%;
    padding: 20px;

    h3 {
        margin-top: 20px;
    }
}

.serviceIcon {
    svg {
        width: 50px;
        height: 50px;
        fill: #9f9fe9;
    }
}

.desc {
    margin-top: 20px;
}

.footer {
    margin: 40px 0 20px;
}

button {
    border-radius: 12px;
    padding: 12px 10px;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    color: #fff;
    border: 2px solid #451c80;
    font-size: 18px;
    font-weight: 500;

    &:active {
        background: rgba(159, 159, 233, 0.5);
    }
}

@media (max-width: 810px) {
    .services {
        flex-direction: column;
    }

    .serviceBlock {
        width: 100%;
    }
}
