.root {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-width: 300px;
}

.captcha {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

label {
  margin: 20px 0 12px;
}

input, textarea {
  border-radius: 12px;
  padding: 12px 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 2px solid #451c80;
  font-size: 18px;

  &:focus-visible {
    outline: none;
  }

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  &::-webkit-scrollbar {
    width: 6px;
    margin-left: 20px;
    padding-left: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 5px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.message {
  max-width: 100%;
  min-width: 100%;
  min-height: 72px;
  resize: none;
  height: 114px;
}

.submit {
  margin-top: 20px;
  width: 160px;
  border-color: #dfdff8;
  background-color: rgba(223, 223, 248, 0.2);
  cursor: pointer;
  font-weight: 500;
  min-height: 48px;

  &:active {
    background: rgba(159, 159, 233, 0.5);
  }
}

.nameContainer {
  display: flex;
  align-items: center;

  >div {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .name {
    margin-right: 20px;
  }
}

.labelName {
  margin-top: 0;
}

@media (max-width: 780px) {
  .captcha {
    flex-direction: column;
    align-items: center;
  }
  .submit {
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .nameContainer {
    flex-direction: column;

    >div {
      width: 100%;
    }

    .name {
      margin-right: 0;
    }
  }
}

@media (max-width: 862px) {
  .root {
    width: 100%;
  }
}
