.root {
  display: flex;
  flex-direction: column;
  width: calc(100% - 320px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  margin: 0 160px;
  padding: 30px 30px 20px;
  align-items: center;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 30px;
  white-space: nowrap;
  margin-bottom: 20px;
  font-weight: 500;
}

.content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (max-width: 930px) {
  .root {
    margin: 0 0 0 160px;
    width: calc(100% - 190px);
  }
}

@media (max-width: 710px) {
  .root {
    width: 100%;
    margin: 0 20px;
  }
}

