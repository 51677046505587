.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  border-radius: 12px;
  padding: 12px 10px;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  color: #fff;
  border: 2px solid #451c80;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.openIcon {
  transition: transform 0.1s ease;
  //position: absolute;
  margin-top: 1px;
  margin-right: 10px;

  svg {
    width: 18px;
    height: 18px;
  }

  &.opened {
    transform: rotate(180deg);
  }
}

.body {
  height: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  visibility: hidden;
  -webkit-transition: visibility 0.3s, height 0.26s, opacity 0.3s linear, transform 0.20s ease;
  transition: visibility 0.3s, height 0.26s, opacity 0.3s linear, transform 0.20s ease;

  &.active {
    height: 100%;
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
